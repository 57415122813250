import React from "react";

export const Avviso = () => {
  return(
      <div className="py-4">
          <p className="text-red-500 text-center font-bold uppercase">
              senza tutti i documenti caricati correttamente l'atleta
              <br/>
              non sarà iscritto e non potrà prendere parte agli allenamenti
          </p>
      </div>
  )
}
