import React from "react";
import headerBg from '../assets/images/header-bg.jpeg'
import logo from '../assets/images/logo.jpg'

export const Header = () => (
    <div className="h-[60vh] max-h-[27.5rem] min-h-[34.375rem] text-white p-0 relative bg-center bg-cover
    before:bg-black/50 before:absolute before:z-0 before:w-full before:h-full before:block before:left-0 before:top-0 before:content-['']
    after:absolute after:z-0 after:w-full after:h-full after:block after:left-0 after:top-0 after:content-['']">
        <div className="absolute w-full h-full bg-center" style={{background: `url(${headerBg}) no-repeat center/cover`, zIndex: '-1'}}/>
        <div className="w-full h-full mx-auto text-center relative px-[0.938rem]">
            <div className="w-full h-full flex flex-col justify-center m-auto pb-4">
                <img src={logo} className="rounded-[50%] mx-auto w-[7.688rem] h-[7.688rem] shadow-2xl"/>
                <h3 className="font-bold mt-7 text-3xl">
                    Iscrizioni
                </h3>
                <p className="text-white/50 uppercase font-bold">
                    atletica cernusco
                </p>
            </div>
        </div>
    </div>
)
