import './App.css';
import {Home} from "./pages/Home";
import {Header} from "./components/Header";
import {Route, Routes} from "react-router-dom";
import {Success} from "./pages/Success";
import {Error} from "./pages/Error";
import {Upload} from "./pages/Upload";

function App() {
    return (
        <div>
            <Header/>
            <Routes>
                <Route element={<div>
                    <p className="text-center font-bold text-4xl mb-6 pt-4 px-2">Le iscrizioni sono al momento chiuse</p>
                </div>} path={'/*'}/>

            </Routes>

        </div>
    );
}

export default App;
